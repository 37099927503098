var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SelectSimple", {
    attrs: { items: _vm.items, label: "Situação", "item-value": "itemValue" },
    model: {
      value: _vm.internalValue,
      callback: function ($$v) {
        _vm.internalValue = $$v
      },
      expression: "internalValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }